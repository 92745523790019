import { render, staticRenderFns } from "./ServiceHistory.vue?vue&type=template&id=f9f5b54e&scoped=true&"
import script from "./ServiceHistory.vue?vue&type=script&lang=js&"
export * from "./ServiceHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9f5b54e",
  null
  
)

export default component.exports